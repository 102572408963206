<template>
  <div id="checkout">
    <div class="checkout">
      <div class="checkout__main">
        <OPersonalDetails
          :get-active-step="getActiveStep"
          :is-logged-in="isLoggedIn"
        />
        <OOrderStepPackage
          v-if="!isDarkstore"
          :get-active-step="getActiveStep"
          :shipping-details="shippingDetails"
        />
        <OOrderStepShipping
          :cart-states="cartStates"
          :get-active-step="getActiveStep"
          :shipping-details-current="shippingDetails"
          :is-new-post="isNewPost"
          @update-shipping-validation-result="updateShippingValidationResult"
        />
        <OOrderStepTimeSlots
          v-if="!isNewPost"
          :get-current-time-slot="getCurrentTimeSlot"
          :get-active-step="getActiveStep"
          :shipping-details="shippingDetails"
          :is-delivery-filled="filledSections.delivery"
          :cart-states="cartStates"
          :shipping-time-slots="shippingTimeSlots"
          :totals="totals"
          @set-sku="setSku"
        />
        <OOrderStepPayment
          :shipping-details="shippingDetails"
          :payment-methods="paymentMethods"
          :get-payment-methods="getPaymentMethods"
          :available-payment-methods="availablePaymentMethods"
          :get-active-step="getActiveStep"
          :get-current-time-slot="getCurrentTimeSlot"
          :get-shops="getShops"
          :is-logged-in="isLoggedIn"
        />
      </div>
      <div class="checkout__aside">
        <div class="checkout__aside-container checkout__aside-container--top">
          <OOrderSummary
            class="checkout__aside-order"
            :is-loading="isCartLoading"
            :total-weight="getWeightFromTotalSegments"
            :cart-validation-message="cartValidationMessage"
            :cart-validation-warning="cartValidationWarning"
            @open-microcart="openMicrocart"
          />
          <div v-if="!isMobile" class="checkout__aside-footer">
            <MCheckoutPlaceButton
              :place-order-is-available="placeOrderIsAvailable"
              :is-validation-processed="isValidationProcessed"
              :payment-method="paymentMethod"
              @place="onBeforePlaceOrder"
              @open-cart="openMicrocart"
            />
            <MCheckoutWarningTerms />
          </div>
        </div>
      </div>
      <template v-if="isMobile">
        <MCheckoutPlaceButton
          :show-button="activeSection.orderReview"
          :place-order-is-available="placeOrderIsAvailable"
          :is-validation-processed="isValidationProcessed"
          :payment-method="paymentMethod"
          @place="onBeforePlaceOrder"
          @open-cart="openMicrocart"
        />
        <div class="checkout__aside-sticky-trigger"></div>
        <div class="checkout__aside-container checkout__aside-container--bottom">
          <MCheckoutWarningTerms />
        </div>
      </template>
      <ALoadingSpinner
        key="spinner"
        v-show="isOrderPreparingToProcess"
        :is-fixed-position="true"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Checkout from 'theme/mixins/Checkout'
import OOrderSummary from 'theme/components/organisms/o-order-summary'
import OPersonalDetails from 'theme/components/organisms/o-personal-details'
import OOrderStepPackage from 'theme/components/organisms/o-order-step-package'
import OOrderStepPayment from 'theme/components/organisms/o-order-step-payment'
import OOrderStepShipping from 'theme/components/organisms/o-order-step-shipping'
import OOrderStepTimeSlots from 'theme/components/organisms/o-order-step-time-slots'
import MCheckoutWarningTerms from 'theme/components/molecules/m-checkout-warning-terms'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import { metaPages } from '../meta/pages';
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import MCheckoutPlaceButton from 'theme/components/molecules/m-checkout-place-button.vue';
import DeviceType from 'theme/mixins/DeviceType';
import CartValidations from 'theme/mixins/CartValidations';

export default {
  name: 'Checkout',
  components: {
    OOrderSummary,
    OPersonalDetails,
    OOrderStepPackage,
    OOrderStepPayment,
    OOrderStepShipping,
    OOrderStepTimeSlots,
    MCheckoutWarningTerms,
    ALoadingSpinner,
    MCheckoutPlaceButton
  },
  mixins: [Checkout, DeviceType, CartValidations],
  data () {
    return {
    };
  },
  mounted () {
    sendToLogs(
      groups.Checkout,
      'checkout:session:start',
      'done'
    )
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      setMicrocart: 'ui/setMicrocart'
    }),
    showNotification ({ type, message }) {
      this.$store.dispatch('notification/spawnNotification', {
        type,
        message,
        action1: { label: this.$t('OK') }
      });
    },
    notifyEmptyCart () {
      this.showNotification({
        type: 'warning',
        message: this.$t(
          'Shopping cart is empty. Please add some products before entering Checkout'
        )
      });
    },
    notifyOutStock (p) {
      this.showNotification({
        type: 'danger',
        message: p.name + this.$t(' is out of stock!')
      });
    },
    notifyNotAvailable () {
      this.showNotification({
        type: 'danger',
        message: this.$t('Some of the ordered products are not available!')
      });
    },
    notifyStockCheck () {
      this.showNotification({
        type: 'warning',
        message: this.$t(
          'Stock check in progress, please wait while available stock quantities are checked'
        )
      });
    },
    notifyNoConnection () {
      this.showNotification({
        type: 'warning',
        message: this.$t(
          'There is no Internet connection. You can still place your order. We will notify you if any of ordered products is not available because we cannot check it right now.'
        )
      });
    },
    setSku (sku) {
      this.sku = sku
    },
    updateShippingValidationResult (validationResult) {
      if (!validationResult) this.filledSections.shipping = false

      this.manualValidations.shipping = validationResult
    },
    openMicrocart () {
      this.setMicrocart(true);
    }
  },
  computed: {
    ...mapGetters({
      getCartItems: 'cart/getCartItems',
      isLoggedIn: 'user/isLoggedIn'
    }),
    placeOrderIsAvailable () {
      if (this.cartValidationMessage) return false

      const filledSections = Object.keys(this.filledSections)
        .filter(k => k !== 'orderReview')
        .map(k => this.filledSections[k])

      const activeSections = Object.keys(this.activeSection)
        .filter(k => k !== 'orderReview')
        .map(k => !this.activeSection[k])

      return [
        ...Object.values(this.validationResults),
        ...filledSections,
        ...activeSections
      ].every((item) => item === true)
    }
  },
  metaInfo: metaPages
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#checkout {
  box-sizing: border-box;

  @media only screen and (min-width: $desktop-min) {
    padding: 0 var(--spacer-17);
  }

  @media only screen and (min-width: $desktop-min) {
    padding: 0 var(--spacer-10);
    max-width: 1210px;
    margin: 0 auto;
  }
}
.checkout {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $tablet-min) {
    flex-direction: row;
    justify-content: center;
  }

  &__main {
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: var(--spacer-15);

    @media only screen and (min-width: $tablet-min) {
      max-width: 585px;
      flex: 1 1 427px;
      padding: var(--spacer-40) 20px 10px;
    }
    @media only screen and (min-width: $desktop-min) {
      padding: var(--spacer-40) 0 100px 20px;
    }
  }

  &__aside {
    box-sizing: border-box;
    padding: var(--spacer-25) 0 0 0;

    @media only screen and (min-width: $tablet-min) {
      padding: var(--spacer-40) var(--spacer-18) 0 var(--spacer-18);
      flex: 1 1 305px;
      border-left: 2px solid var(--light-gray);
    }

    @include for-desktop {
      flex: 0 0 427px;
      padding: var(--spacer-40);
      padding-left: var(--spacer-15);
      padding-right: 20px;
      padding-bottom: 100px;
    }

    &-order {
      box-sizing: border-box;
      width: 100%;

      @media only screen and (min-width: $tablet-min) {
        padding: 0;
      }
    }
  }

  &__aside-container {
    display: flex;
    flex-direction: column;
    background: var(--white);
    padding: var(--spacer-24);

    &--top {
      @media (max-width: $mobile-max) {
        padding-bottom: var(--spacer-16);
      }
    }

    &--bottom {
      @media (max-width: $mobile-max) {
        padding-top: var(--spacer-16);
      }
    }

    @media only screen and (min-width: $tablet-min) {
      position: sticky;
      top: var(--spacer-40)
    }
  }

  &__notification-price-change {
    margin: 0 0 var(--spacer-15);
  }

  &__aside-footer {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--gray3);
    padding: var(--spacer-15) 0 0;
  }

  ::v-deep {
    .sf-button--full-width-mobile {
      font-size: var(--font-size-14);
      @media (max-width: $mobile-max) {
        width: 100%;
      }
    }

    .sf-input--filled input:focus {
      border: 1px solid var(--orange);
    }

    .o-order-step-content__comment-button {
      padding: 0;
      border: 0;
      font-size: var(--font-size-14);
      width: 100%;
      color: var(--orange);
      text-align: left;
      background: none;
      cursor: pointer;
    }

    @media (max-width: $tablet-max) {
      .sf-select {
        &__dropdown {
          left: var(--spacer-20);
          right: var(--spacer-20);
          bottom: var(--spacer-20);
          width: unset;
          background: none;
        }

        &__options {
          border-radius: var(--spacer-5);
          background: var(--c-white);
        }

        &__cancel {
          margin-top: var(--spacer-8);
          border-radius: var(--spacer-5);
          background: var(--c-white);
        }
      }

      .sf-select__dropdown {
        .sf-select-option {
          padding:var(--spacer-20) var(--spacer-24);
        }

        .sf-select-option:not(:last-child) {
          border-bottom: 1px solid var(--line-gray);
        }
      }
    }
  }
}
</style>
