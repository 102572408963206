<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    class="a-pmi"
    :class="{'a-pmi--active': active}"
  >
    <APaymentIcon class="a-pmi__type" :type="item.card_type" />
    <span class="a-pmi__label">{{ String(item.card_mask) }}</span>
  </button>
</template>

<script>
import APaymentIcon from 'theme/components/atoms/a-payment-icon.vue';

export default {
  name: 'APaymentItem',
  components: {
    APaymentIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-pmi {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid var(--gray-border);
  border-radius: var(--spacer-10);
  height: var(--spacer-45);
  padding: var(--spacer-10);
  background: transparent;
  gap: var(--spacer-12);
  text-align: left;
  width: 100%;
  cursor: pointer;
  transition: 200ms;
  color: var(--black);

  &--active {
    border-color: var(--orange);
    background: var(--orange-light-background);
  }

  &__label {
    flex: 1;
    font-size: var(--font-size-14);
    line-height: var(--spacer-17);
    font-weight: 500;
  }

  &__type {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
