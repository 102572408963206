<template>
  <div class="m-bls">
    <button
      v-for="(item, index) in items"
      :key="index"
      class="m-bls__item"
      :class="{'m-bls__item--active': item.code === selected}"
      @click="setItem(item)"
    >
      {{ $t(item.title) }}
    </button>
  </div>
</template>
<script>

export default {
  name: 'MPaymentSelect',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    setItem (item) {
      this.$emit('set-item', item.code)
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~theme/css/fonts';

.m-bls {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-12);
  padding-top: var(--spacer-8);

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--gray-border);
    background: none;
    min-height: var(--spacer-45);
    text-align: left;
    padding: var(--spacer-12);
    border-radius: var(--spacer-10);
    cursor: pointer;
    color: var(--black);
    font-size: var(--font-size-14);
    transition: 200ms;

    &--active {
      border-color: var(--orange);
      background: var(--orange-light-background);
    }
  }
}
</style>
