<template>
  <button
    class="m-tfi"
    :class="{'m-tfi--active': active}"
  >
    <span class="m-tfi__label">
      <span v-if="isPriority" class="m-tfi__priority"></span>

      <span>{{ item.value }}</span>
    </span>
    <span v-if="item.price || isFree" class="m-tfi__bottom">
      <span v-if="isDiscount">
        <span class="m-tfi__price m-tfi__price--cross">{{ originalPrice }}</span>
        <span class="m-tfi__price m-tfi__price--discount">{{ item.price | price }}</span>
      </span>
      <span v-else-if="isPaid" class="m-tfi__price">{{ item.price | price }}</span>
      <span v-else-if="isFree" class="m-tfi__price">{{ $t('free delivery') }}</span>
      <span v-if="false" class="m-tfi__discount">-25% {{ $t('on premium') }}</span>
    </span>
  </button>
</template>

<script>

import { shipping } from 'config'

export default {
  name: 'ATimeslotItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isPaid () {
      return this.item.price && !this.isFree
    },
    originalPrice () {
      return this.item?.original_price?.toFixed(2)
    },
    isDiscount () {
      return this.isPaid && this.item.original_price && this.item.price !== this.item.original_price
    },
    isFree () {
      return !!this.item?.free_shipping
    },
    isPriority () {
      return this.item?.delivery_method?.toUpperCase() === shipping.codes.deliveryPriority
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@storefront-ui/shared/styles/helpers/breakpoints';
@import '~theme/css/fonts';
@import "~theme/css/mixins";

.m-tfi {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid var(--gray-lighter);
  border-radius: var(--spacer-10);
  padding: var(--spacer-10);
  background: transparent;
  gap: 4px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  transition: 200ms;
  color: var(--black);
  min-height: var(--spacer-40);

  &--active {
    border-color: var(--orange);
    background: var(--orange-light-background);

    .m-tfi__price {
      color: var(--black);
    }

    .m-tfi__price--discount {
      color: var(--orange);
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacer-2);
    font-size: var(--font-size-14);
    line-height: var(--spacer-17);
    font-weight: 500;
  }

  &__price {
    line-height: var(--spacer-16);
    font-size: var(--font-size-12);
    font-weight: 400;
    color: var(--dark-gray);

    &--cross {
      text-decoration: line-through;
    }

    &--discount {
      font-weight: 600;
      color: var(--orange);
    }
  }

  &__discount {
    font-size: var(--font-size-10);
    line-height: var(--spacer-14);
    font-weight: 500;
    color: var(--green-action);
  }

  &__priority {
    color: var(--orange);
    font-size: var(--font-size-16);
    @include font-icon(var(--icon-fire-2));
  }
}
</style>
