<template>
  <div
    class="checkout-place"
    ref="checkoutPlace"
    :class="{ 'checkout-place--sticky': !isDisabled }"
  >
    <SfButton
      :disabled="isDisabled"
      data-transaction-name="Checkout - Place Order"
      @click="placeHandler()"
      class="sf-button--primary checkout-place__button"
    >
      <template v-if="isValidationProcessed">
        <SfLoader
          :background="`transparent`"
        />
      </template>
      <template v-else>
        {{ $t(paymentMethod === 'HOLD' ? "Pay the order" : "Place the order") }}
      </template>
    </SfButton>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import SfLoader from 'theme/components/storefront-override/SfLoader.vue';

export default {
  name: 'MCheckoutPlaceButton',
  components: { SfLoader, SfButton },
  props: {
    placeOrderIsAvailable: {
      type: Boolean,
      required: true
    },
    isValidationProcessed: {
      type: Boolean,
      required: true
    },
    showButton: {
      type: Boolean,
      default: () => true
    },
    paymentMethod: {
      type: String,
      default: () => null
    }
  },
  computed: {
    isDisabled () {
      return !this.showButton || !this.placeOrderIsAvailable || this.isValidationProcessed
    }
  },
  watch: {
    isDisabled (val) {
      if (val) return

      this.$nextTick(() => this.stickyCheck())
    }
  },
  data () {
    return {
      isStuck: false
    }
  },
  methods: {
    openMicrocart () {
      this.$emit('open-microcart')
    },
    placeHandler () {
      this.$emit('place')
    },
    stickyCheck () {
      if (!this.$refs.checkoutPlace.classList.contains('checkout-place--sticky')) {
        if (this.isStuck) {
          this.$refs.checkoutPlace.classList.remove('checkout-place--stuck')
          this.isStuck = false
        }

        return
      }

      const rect = document
        .querySelector('.checkout__aside-sticky-trigger')
        ?.getBoundingClientRect()

      if (!rect) return

      const isVisible = (
        rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )

      if ((this.isStuck && !isVisible) || (!this.isStuck && isVisible)) return;

      if (this.isStuck && isVisible) {
        this.$refs.checkoutPlace.classList.remove('checkout-place--stuck')
        this.isStuck = false

        return
      }

      this.$refs.checkoutPlace.classList.add('checkout-place--stuck')
      this.isStuck = true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.stickyCheck);

    this.stickyCheck();
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.stickyCheck);
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.checkout-place {
  &--sticky {
    position: sticky;
    bottom: 0;
  }

  @media (max-width: $mobile-max) {
    transition: all 200ms;
    background: var(--white);
    padding-left: var(--spacer-24);
    padding-right: var(--spacer-24);
  }

  &--stuck {
    @media (max-width: $mobile-max) {
      padding: 0;
    }
  }

  @media only screen and (min-width: $tablet-min) {
    position: static;
  }

  &__button {
    width: 100%;
    --button-height: var(--spacer-56);

    &:disabled {
      --button-background: var(--orange-disabled);
      --button-color: var(--white);
    }

    &:not(:disabled) {
      &:hover {
        --button-background: var(--orange-hover);
      }
    }
  }
}
</style>
