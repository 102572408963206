<template>
  <div class="mls-c">
    <div
      v-if="!isDesktop"
      class="mls-c__scrollable"
    >
      <div class="mls-c__scrollable-line">
        <div
          v-for="(item, index) in items"
          :key="`item-${index}`"
        >
          <slot name="item" v-bind="{ item }" />
        </div>
      </div>
    </div>
    <SfOCarousel
      v-else
      ref="carousel"
      :settings="sliderSettings"
    >
      <template #prev="{go, isFirst}">
        <SfButton
          v-if="!isFirst"
          class="sf-arrow sf-arrow--rounded sf-arrow-prev mls-c__prev"
          aria-label="previous"
          data-transaction-name="Timeslot Carousel - Prev"
          @click="go('prev')"
        />
        <span v-else />
      </template>
      <template #next="{go, isLast}">
        <SfButton
          v-if="!isLast"
          class="sf-arrow sf-arrow--rounded sf-arrow-prev mls-c__next"
          aria-label="next"
          data-transaction-name="Timeslot Carousel - Prev"
          @click="go('next')"
        />
        <span v-else />
      </template>
      <SfCarouselItem
        v-for="(item, index) in items"
        :key="`item-${index}`"
      >
        <slot name="item" v-bind="{ item }" />
      </SfCarouselItem>
    </SfOCarousel>
  </div>
</template>
<script>

import { SfButton } from '@storefront-ui/vue';
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel.vue';
import { BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XL } from 'theme/helpers/breakpoints';
import DeviceType from 'theme/mixins/DeviceType';

const breakpoints = {
  XL: {
    width: BREAKPOINT_XL - 1,
    items: 4
  },
  MD: {
    width: BREAKPOINT_MD - 1,
    items: 3
  },
  SM: {
    width: BREAKPOINT_SM - 1,
    items: 2
  }
}

export default {
  name: 'MListSelect',
  components: { SfOCarousel, SfButton },
  mixins: [DeviceType],
  props: {
    /**
     * Message that will be displayed in info block.
     */
    sliderSettings: {
      type: Object,
      default: () => ({
        type: 'slider',
        perView: 6,
        rewind: false,
        dragThreshold: false,
        gap: 8,
        breakpoints: {
          [breakpoints.XL.width]: {
            peek: {
              before: 0,
              after: 0
            },
            perView: breakpoints.XL.items
          },
          [breakpoints.MD.width]: {
            peek: {
              before: 0,
              after: 0
            },
            perView: breakpoints.MD.items
          },
          [breakpoints.SM.width]: {
            peek: {
              before: 0,
              after: 40
            },
            perView: breakpoints.SM.items,
            swipeThreshold: false,
            dragThreshold: false
          }
        }
      })
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    items: {
      handler: function () {
        this.$nextTick(() => {
          this.$refs?.carousel?.initGlide()
        })
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~theme/css/fonts';
@import 'theme/css/breakpoints';
@import "~theme/css/px2rem";

.mls-c {
  position: relative;
  width: 100%;

  &__scrollable {
    --product-scrollable-gap: var(--spacer-10);
    //overflow: auto hidden;
    overflow: auto hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    @-moz-document url-prefix() {
      scrollbar-width: none;
    }

    &-line {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      gap: var(--spacer-10);
      padding-left: var(--spacer-10);
      padding-right: var(--spacer-10);
      box-sizing: content-box;

      > * {
        min-width: 155px;
        max-width: 155px;

        &:last-child {
          margin-right: var(--spacer-10);
        }
      }
    }
  }

  :deep(.sf-carousel__controls) {
    z-index: 1;
    margin: 0;
  }

  :deep(.mls-c__prev), :deep(.mls-c__next) {
    --button-width: 32px;
    --button-height: 32px;

    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--light-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -16px 0 0;
  }

  :deep(.mls-c__prev) {
    position: absolute;
    left: -10px;
    right: initial;
  }

  :deep(.mls-c__next) {
    position: absolute;
    left: initial;
    right: -10px;

    &:before {
      transform: rotate(180deg);
    }
  }

  ::v-deep {
    .sf-product-card__wrapper {
      height: 100%;
    }

    .sf-carousel-item {
      height: auto;

      .sf-product-card {
        height: 100%;
      }
      .sf-price__special {
        @include for-mobile {
          margin-bottom: 2px;
        }
      }
    }

    .glide__slide {
      @include for-mobile {
        width: auto!important;
      }
    }

    .glide__track,
    .glide__slide {
      @include for-mobile {
        overflow: auto hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          scrollbar-width: none;
        }
      }
    }

    .glide__slides {
      @include for-mobile {
        overflow: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          scrollbar-width: none;
        }
      }
    }
  }
}
</style>
