<template>
  <div class="cwt-c">
    <div class="cwt-c__item">
      {{ $t('By continuing, you confirm that you have read and agree to the') }}
      <span
          class="cwt-c__link"
          @click="onUserAgreementClick"
      >
        {{ $t("rules") }}
      </span>
    </div>
    <div class="cwt-c__item">
      {{ $t('The cost of packing materials will be added to the total order amount.') }}
      <span
        class="cwt-c__icon"
        @click="onInfoClick"
      ></span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ModalList } from 'theme/store/ui/modals'

export default {
  name: 'MCheckoutWarningTerms',
  methods: {
    ...mapActions({
      openModal: 'ui/openModal'
    }),
    onUserAgreementClick () {
      this.openModal({ name: ModalList.TermsAndConditionsUserAgreement })
    },
    onInfoClick () {
      this.openModal({ name: ModalList.ShortPackageInfo })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.cwt-c {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-8);

  @media only screen and (min-width: $tablet-min) {
    margin: var(--spacer-20) 0 0 0;
    padding: 0;
  }

  &__item {
    line-height: 140%;
    font-size: 13px;
    color: var(--dark-gray);
  }

  &__link {
    color: var(--orange);
    cursor: pointer;
  }

  &__icon {
    @include font-icon(var(--icon-info-outline));
  }

  &__link, &__icon {
    cursor: pointer;
  }
}
</style>
