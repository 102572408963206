import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

export const bindTransactionToOrder = async (orderConfirmationData) => {
  const { order_id: orderId, hold_data: holdData } = orderConfirmationData;
  const checkoutStorage = StorageManager.get('checkout');
  const transactionMapObj = await checkoutStorage.getItem('transaction-map') || {};

  transactionMapObj[orderId] = holdData.transaction_id;

  await checkoutStorage.setItem('transaction-map', transactionMapObj);
}

export const getTransactionIdByOrderId = async (orderId) => {
  const checkoutStorage = StorageManager.get('checkout');
  const transactionMapObj = await checkoutStorage.getItem('transaction-map') || {};

  return transactionMapObj[orderId];
}
