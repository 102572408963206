<template>
  <div class="m-order-step-payment-card">
    <MListSelect
      :items="cards"
      :slider-settings="sliderSettings"
    >
      <template #item="{ item }">
        <APaymentItem
          :active="checkActive(item)"
          :item="item"
          @click="changeSelectedCard(item.card_mask)"
        />
      </template>
    </MListSelect>

    <SfLoader v-if="isLoading" />

    <div
      class="m-order-step-payment-card__add-card"
      :class="{'m-order-step-payment-card__add-card--margin': !!cards.length }"
      @click="addCard"
    >
        <span class="m-order-step-payment-card__add-card-title">
          {{ $t('Add card') }}
        </span>
    </div>
  </div>
</template>

<script>
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'
import PaymentCard from 'theme/mixins/PaymentCard';
import MListSelect from 'theme/components/molecules/m-list-select.vue';
import APaymentItem from 'theme/components/atoms/a-payment-item.vue';
import { BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XL } from 'theme/helpers/breakpoints';

const breakpoints = {
  XL: {
    width: BREAKPOINT_XL - 1,
    items: 2
  },
  MD: {
    width: BREAKPOINT_MD - 1,
    items: 2
  },
  SM: {
    width: BREAKPOINT_SM - 1,
    items: 2
  }
}

export default {
  name: 'MOrderStepPaymentCard',
  mixins: [
    PaymentCard
  ],
  components: {
    APaymentItem,
    MListSelect,
    SfLoader
  },
  data () {
    return {
      sliderSettings: {
        type: 'slider',
        perView: 2,
        rewind: false,
        keyboard: true,
        dragThreshold: false,
        gap: 8,
        peek: {
          before: 0,
          after: 60
        },
        breakpoints: {
          [breakpoints.XL.width]: {
            perView: breakpoints.XL.items,
            peek: {
              before: 0,
              after: 0
            }
          },
          [breakpoints.MD.width]: {
            perView: breakpoints.MD.items
          },
          [breakpoints.SM.width]: {
            peek: {
              before: 0,
              after: 0
            },
            perView: breakpoints.SM.items,
            swipeThreshold: false
          }
        }
      }
    }
  },
  methods: {
    changeSelectedCard (cardMask) {
      this.selectedCard = this.cards?.find(card => String(card.card_mask) === String(cardMask))
    },
    checkActive (item) {
      return this.selectedCard && (this.selectedCard.card_mask === item.card_mask)
    }
  },
  watch: {
    'selectedCard': function () {
      this.$emit('cardSelected', this.selectedCard)
    }
  }
};
</script>

<style lang="scss" scoped>
  .m-order-step-payment-card {
    width: 100%;

    &__item {
      --radio-container-padding: 0 0 var(--spacer-10);
      --radio-content-margin: 0 0 0 var(--spacer-13);

      &.sf-radio--is-active {
        background: none;
      }

      ::v-deep .sf-radio__content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    &-item {
      &__icon {
        margin-right: var(--spacer-10);
      }

      &__pan {
        font-size: var(--font-size-15);
        line-height: var(--font-size-18);
        color: var(--black);
      }
    }

    &__add-card {
      display: flex;
      cursor: pointer;
      align-items: center;

      &--margin {
        margin-top: var(--spacer-16);
      }

      &-title {
        margin-bottom: var(--spacer-8);
        color: var(--orange);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
      }
    }
  }
</style>
