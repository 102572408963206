<template>
  <span>
    <svg v-if="type === 'MasterCard'" width="40" height="27" viewBox="0 0 40 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.3337 13.4058C33.3337 18.0179 29.6484 21.7492 25.0933 21.7492C23.1706 21.7492 21.4081 21.0771 20.0118 19.9647C21.9345 18.435 23.1477 16.0711 23.1477 13.4058C23.1477 10.7406 21.9116 8.37667 20.0118 6.84705C21.4081 5.73461 23.1706 5.0625 25.0933 5.0625C29.6484 5.0625 33.3337 8.81701 33.3337 13.4058Z" fill="#F79E1B"/>
      <path d="M20.0118 6.84705C20.0118 6.84705 20.0118 6.84705 20.0118 6.84705C21.9116 8.37667 23.1477 10.7406 23.1477 13.4058C23.1477 16.0711 21.9345 18.435 20.0118 19.9647L19.9888 19.9646C18.089 18.4582 16.853 16.0711 16.853 13.4059C16.853 10.7407 18.089 8.37671 19.9889 6.8471C19.9888 6.84708 19.9889 6.84712 19.9889 6.8471L20.0118 6.84705Z" fill="#FF5F00"/>
      <path d="M16.853 13.4059C16.853 10.7407 18.089 8.37671 19.9889 6.8471C18.5926 5.73465 16.8301 5.06254 14.9073 5.06254C10.3523 5.06254 6.66699 8.79387 6.66699 13.4059C6.66699 18.0179 10.3523 21.7492 14.9073 21.7492C16.8301 21.7492 18.5925 21.0771 19.9888 19.9646C18.089 18.4582 16.853 16.0711 16.853 13.4059Z" fill="#EB001B"/>
    </svg>

    <svg v-else width="40" height="27" viewBox="0 0 40 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.0825 8.8539C26.5899 8.656 25.8085 8.4375 24.8423 8.4375C22.3757 8.4375 20.6387 9.76925 20.6281 11.6732C20.6076 13.078 21.8717 13.8583 22.8171 14.3266C23.7835 14.8053 24.112 15.1177 24.112 15.5443C24.1021 16.1995 23.3311 16.5016 22.612 16.5016C21.6148 16.5016 21.0805 16.3458 20.2685 15.9813L19.9396 15.825L19.59 18.0206C20.1759 18.2908 21.2551 18.5306 22.3757 18.5411C24.9965 18.5411 26.7027 17.2299 26.7229 15.2009C26.7329 14.0875 26.0654 13.2344 24.6263 12.5373C23.7527 12.0898 23.2177 11.788 23.2177 11.3301C23.228 10.9139 23.6702 10.4875 24.6564 10.4875C25.4684 10.4667 26.065 10.6643 26.5169 10.862L26.7428 10.9659L27.0825 8.8539Z" fill="#3362AB"/>
      <path d="M18.0281 18.3953H15.5304L17.0926 8.61479H19.5902L18.0281 18.3953Z" fill="#3362AB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33.4853 8.61479H31.5533C30.9575 8.61479 30.5049 8.79153 30.2478 9.42628L26.5378 18.3952H29.1586C29.1586 18.3952 29.5901 17.188 29.6828 16.928H32.8896C32.9614 17.2714 33.1877 18.3952 33.1877 18.3952H35.5003L33.4853 8.61479ZM30.4022 14.9304C30.6079 14.3685 31.3994 12.1939 31.3994 12.1939C31.3952 12.2023 31.4274 12.1119 31.4754 11.9772L31.476 11.9756L31.4764 11.9745C31.5481 11.7732 31.6545 11.4745 31.728 11.2575L31.9026 12.1003C31.9026 12.1003 32.3755 14.4414 32.4783 14.9304H30.4022Z" fill="#3362AB"/>
      <path d="M13.4442 8.61479L10.9981 15.2842L10.7308 13.9316L10.7305 13.9307L9.85719 9.43693C9.71337 8.81227 9.27138 8.63539 8.72668 8.61479H4.7081L4.66699 8.81241C5.64711 9.06618 6.52373 9.43185 7.29184 9.88653L9.51805 18.385H12.1593L16.0853 8.61479H13.4442Z" fill="#3362AB"/>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'APaymentIcon',
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script>
