<template>
  <div class="o-order-summary">
    <div class="o-order-summary__header">
      <SfHeading
        :title="$t('Your order')"
        :level="3"
        class="sf-heading--left sf-heading--no-underline"
      />
      <SfButton
        data-transaction-name="Checkout - Summary - Microcart"
        @click="openMicrocart()"
        class="sf-button--pure o-order-summary__btn--edit"
      >
        {{ $t('Shopping cart') }}
      </SfButton>
    </div>
    <MSummaryNotification
      :cart-validation-message="cartValidationMessage"
      :cart-validation-warning="cartValidationWarning"
      class="o-order-summary__error" />
    <APromoCode class="o-order-summary__promo" allow-promo-code-removal />
    <ASmoothReflow>
      <ATotalsPlaceholder v-if="isLoading" />
      <MPriceSummary v-else :is-large="true" :total-weight="totalWeight" />
    </ASmoothReflow>
  </div>
</template>
<script>
import { SfHeading, SfButton } from '@storefront-ui/vue'
import APromoCode from 'theme/components/atoms/a-promo-code'
import MPriceSummary from 'theme/components/molecules/m-price-summary-checkout'
import MSummaryNotification from 'theme/components/molecules/modals/m-summary-notification';
import DeviceType from 'theme/mixins/DeviceType';
import ATotalsPlaceholder from 'theme/components/atoms/a-totals-placeholder.vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';

export default {
  name: 'OOrderSummary',
  components: {
    ASmoothReflow,
    ATotalsPlaceholder,
    SfHeading,
    APromoCode,
    MPriceSummary,
    SfButton,
    MSummaryNotification
  },
  mixins: [DeviceType],
  data () {
    return {
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    totalWeight: {
      type: Number,
      default: () => 0
    },
    cartValidationMessage: {
      type: [Object, null],
      default: () => null
    },
    cartValidationWarning: {
      type: [Object, null],
      default: () => null
    }
  },
  methods: {
    openMicrocart () {
      return this.$emit('open-microcart')
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";

.o-order-summary {
  &__error {
    padding-top: 0;
  }

  &__promo {
    margin-bottom: var(--spacer-16);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacer-20);
    --heading-title-margin: 0;
    --heading-padding: 0;
    --heading-title-font-size: var(--font-size-20);
    --heading-title-font-weight: var(--font-medium);
    --heading-title-color: var(--black);

    @media only screen and (min-width: $tablet-min) {
      margin: 0 0 var(--spacer-15);
    }

    @media only screen and (min-width: $desktop-min) {
      margin: 0 0 var(--spacer-20);
    }
  }
  ::v-deep {
    .sf-heading__title {
      @include header-title(22, 22);
    }
    .sf-link {
      line-height: calc(17/var(--font-sm));
    }
  }

  &__btn {
    &--edit {
      --button-color: var(--c-primary);
      --button-text-decoration: none;
      --button-font-size: var(--font-sm);
      --button-font-weight: var(--font-normal);
    }
  }
}
.title {
  --heading-title-margin: 0 0 var(--spacer-2xl) 0;
}
.property {
  margin: var(--spacer-base) 0;
}
.characteristics {
  margin: var(--spacer-xl) 0 0 var(--spacer-xs);
  &__item {
    margin: var(--spacer-base) 0;
  }
}
.actions {
  &__button {
    margin: var(--spacer-sm) 0;
    &--secondary {
      text-align: left;
    }
  }
}
</style>
