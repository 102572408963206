<template>
  <div class="a-swt">
    <SfButton
      v-for="(item, i) in items"
      :key="i"
      :class="checkClass(item.value)"
      :data-transaction-name="`Section Tab - ${item.value}`"
      class="sf-button--pure a-swt__item"
      @click="setActive(item)"
    >
      {{ item.label }}
    </SfButton>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'ASwitchTabs',
  components: { SfButton },
  props: {
    activeTab: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    checkClass (value) {
      return {
        'a-swt__item--active': value === this.activeTab
      }
    },
    setActive (item) {
      this.$emit('click', item)
    }
  }
};
</script>
<style lang="scss">

.a-swt {
  display: flex;
  gap: var(--spacer-40);

  &__item {
    color: var(--orange);
    font-weight: var(--font-normal);
    padding-bottom: var(--spacer-6);
    position: relative;
    white-space: nowrap;

    &:after {
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      height: 2px;
      background-color: var(--orange);
      opacity: 0;
      transition: .2s ease;
    }

    &--active {
      color: var(--black);

      &:after {
        opacity: 1;
      }
    }

    span {
      padding: 0;
    }
  }
}

</style>
