<template>
  <div
    class="a-textarea"
    :class="className"
  >
    <div
      contenteditable
      ref="input"
      v-focus
      :style="inputStyle"
      @input="onInput"
      @blur="($emit('blur')) && (focused = false)"
      :id="`textarea-${id}`"
      class="a-textarea__field"
      :class="{ 'focused': focused }"
      @focus="focused = true"
      @paste="onInput"
    />

    <label
      :for="`textarea-${id}`"
      class="a-textarea__label"
    >
      {{ resultPlaceholder }}
    </label>
    <SfButton
      class="sf-button--pure a-textarea__clear"
      data-transaction-name="Textarea - Clear"
      @click="clear"
    >
      <svg
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m4 12 8-8 .5.5-8 8L4 12Z" fill="#333" />
        <path d="m12 12.5-8-8 .5-.5 8 8-.5.5Z" fill="#333" />
      </svg>
    </SfButton>
  </div>
</template>

<script>
import { focus } from '@storefront-ui/vue/src/utilities/directives';
import {
  SfButton
} from '@storefront-ui/vue';

export default {
  name: 'ATextarea',
  directives: {
    focus
  },
  components: {
    SfButton
  },
  data () {
    return {
      focused: false,
      inputValue: ''
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
      default: ''
    },
    value: {
      type: [String, Number],
      required: true,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    alternativePlaceholder: {
      type: String,
      required: false,
      default: ''
    },
    isAutoHeight: {
      type: Boolean,
      required: false,
      default: false
    },
    minHeight: {
      type: Number,
      default: 40
    }
  },
  mounted () {
    if (!this.value) return

    this.setText(this.value)
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue && this.notEqual(newValue)) {
          this.setText(newValue)
        }
      }
    }
  },
  computed: {
    inputStyle () {
      return {
        'height': 'auto',
        'min-height': `${this.minHeight}px`
      }
    },
    className () {
      return {
        'a-textarea--empty': this.value === '',
        'a-textarea--has-text': this.value !== '',
        'a-textarea--h-auto': this.isAutoHeight
      }
    },
    resultPlaceholder () {
      return this.focused === true || this.value !== '' ? this.alternativePlaceholder : this.placeholder
    }
  },
  methods: {
    onInput (e) {
      this.inputValue = (e.target.innerText || e.target.textContent).trim();

      this.$emit('input', this.inputValue);
    },
    clear () {
      this.setText('')
      this.$emit('clear')
    },
    notEqual (newValue) {
      const ref = this.$refs.input.innerText || this.$refs.input.textContent
      const value = ref ? ref.trim() : ''

      return newValue !== this.inputValue && newValue !== value
    },
    setText (text) {
      this.$refs.input.innerText = text
      this.inputValue = text
    }
  }
}
</script>

<style lang="scss" scoped>
.a-textarea {
  position: relative;
  width: 100%;
  font-size: 0;

  &--sm {
    .a-textarea__field {
      transition: height 100ms;
      white-space: pre-line;
      height: 40px;
    }
  }

  &--filled {
    .a-textarea__field {
      background: var(--light-gray);
    }
  }

  &--has-text {
    .a-textarea__label {
      font-size: var(--font-size-12);
      top: 2px;
    }
  }

  &--h-auto {
    .a-textarea__field {
      overflow: hidden;
    }
  }

  &--empty {
    .a-textarea__clear {
      display: none;
    }
  }

  &__field {
    width: 100%;
    padding: var(--textarea-field-padding, var(--spacer-20) var(--spacer-sm) var(--spacer-5) var(--spacer-15)) ;
    border: none;
    resize: none;
    background: inherit;
    box-sizing: border-box;
    line-height: var(--textarea-field-line-height, var(--font-size-15));
    overflow-y: hidden;
    font-size: var(--textarea-field-font-size, var(--font-size-15));

    &:hover, &:focus {
      outline: none;
      border: none;
    }

    &:focus {
      ~ .a-textarea__label {
        font-size: var(--font-size-12);
        top: 2px;
      }
    }
  }

  &__label {
    font-size: var(--font-size-15);
    font-weight: var(--font-normal);
    color: var(--dark-gray);
    position: absolute;
    pointer-events: none;
    left: var(--spacer-sm);
    top: 9px;
    transition: 0.2s ease all;
  }

  &__clear {
    position: absolute;
    right: var(--spacer-5);
    top: var(--spacer-5);
  }
}
</style>
