<template>
  <div class="a-cm" :class="variantClass">
    <div class="a-cm__content">{{ message }}</div>
  </div>
</template>

<script>

export default {
  name: 'ACheckoutMessage',
  props: {
    message: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    variantClass () {
      if (!this.variant) return []

      return [`a-cm--${this.variant}`]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-cm {
  background: var(--orange-light-background);
  max-width: 100%!important;

  &--warning {
    background: var(--yellow-corn-silk);
  }

  &__content {
    padding: var(--spacer-10);
    font-size: var(--font-size-13);
  }
}
</style>
